<template>
    <section id="contact-me">
        <v-overlay :value="sent" ></v-overlay>
        <v-snackbar v-model="sent">
            {{ snackbarText }}
            <v-btn text color="primary" @click.native="sent = false">Close</v-btn>
        </v-snackbar>
         <v-container class="" align-center justify-center>
            <v-row>
              <v-col>
                  <v-card class="mb-3 pa-3" elevation="8" max-width="" >
                      <v-card-title primary-title align-center >
                          Contacto
                      </v-card-title>
                       <v-container class="">
                          <v-row>
                            <v-col mx-2>
                                <v-card class="mb-3 pa-3" >
                                    <v-form ref="mailform" @submit.prevent="sendEmail">
                                    <v-card-title primary-title>
                                        <v-text-field v-model="nombreU" :rules="nrules" outlined prepend-icon="mdi-account" placeholder="Nombre y apellidos"  label="Nombre Completo" ></v-text-field>
                                    </v-card-title>
                                    <v-card-title primary-title>
                                        <v-text-field v-model="emailU" :rules="emailrules" outlined label="Correo electrónico" placeholder="email@ejemplo.com" prepend-icon="mdi-email-edit" ></v-text-field>                                        
                                    </v-card-title>
                                    <v-card-title primary-title>
                                        <v-text-field type="number" v-model="telefonoU" :rules="telefonorules" outlined label="Telefono" placeholder="1234567890" prepend-icon="mdi-card-account-phone-outline" ></v-text-field>
                                    </v-card-title>
                                    <v-card-title primary-title>
                                          <v-textarea
                                                  outlined
                                                  color="primary"
                                                  placeholder= "Favor de poner su mensaje o solicitud!! "
                                                  error-count=""
                                                  prepend-icon="mdi-forum"
                                                  name=""
                                                  label="Su mensaje"     
                                                  :rules="mensajerules"
                                                  v-model="textU"
                                                ></v-textarea> 
                                    </v-card-title>
                                    <v-btn type="submit" :disabled="sent" large elevation-10 block color="red darken-1"> 
                                        <v-icon  left color="dark">mdi-email-send</v-icon>                                        
                                        Enviar
                                    </v-btn>
                                   </v-form> 
                                </v-card>                                
                            </v-col>
                          </v-row>
                        </v-container>
                    </v-card>
              </v-col>
            </v-row>
          </v-container>
    </section>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            nrules: [
                value => !!value || 'Requerido.',
                value => (value && value.length >= 3) || 'Mínimo 3 Caracteres',
            ],
            emailrules:[
                value => !!value || 'Requerido.',
                //value=> new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+(?:[A-Z]{2}|com|org|net|gov|gob|mil|biz|info|mobi|name|aero|jobs|museum)").test(value)|| 'Correo no válido',
                value=> new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?").test(value)|| 'Correo no válido',
            ],
            mensajerules:[
                value => !!value || 'Requerido.',
                value => (value && value.length >= 10) || 'Mínimo 10 Caracteres',
            ],
            telefonorules:[
                value => !!value || 'Requerido.',               
                value => (value && value.length ==10) || 'Deben ser  10 Caracteres',
                value => new RegExp("[0-9]{10}").test(value)|| 'Deben ser  10 Números',
            ],
            sent:false,
            nombreU: '',
            emailU: '',
            textU: '',
            telefonoU: '',
            snackbarText:'',
        }
    },
    methods: {
        sendEmail(e){             
            e.preventDefault();
            if(this.$refs.mailform.validate()){                
                axios.post('/contactus.php', {
                    name: this.nombreU,
                    from: this.emailU,
                    message: this.textU,
                    telefono: this.telefonoU
                })
                .then(response => {                    
                    this.nombreU = '';
                    this.emailU = '';
                    this.textU = '';
                    this.telefonoU = '';
                    console.log(JSON.stringify(response));
                    this.snackbarText="Enviado Correctamente"
                })
                .catch(error => {
                    if(error.response){                        
                        console.log(error.response.data);
                        try{
                            this.snackbarText=error.response.data.message||error.response.data.error||error.response.message || error.response.data;
                        }
                        catch(e){
                            this.snackbarText="Error en el servidor";
                        }
                    }
                    else{
                        this.snackbarText="Error al tratar de enviar el correo";
                    }
                    console.log(this.snackbarText);
                })
                .finally(() => {
                    this.sent = true;
                })

            }
        }
    }

}
</script>